import { Component, Input } from '@angular/core';
import { FabItemDescriptor } from '@shared/models/fabItemDescriptor';

import { fabMenuAnimations } from './fab-menu.animations';

/**
 * Komponente für die Darstellung eines FAB-Button Menüs
 */
@Component({
    selector: 'k5-fab-menu',
    templateUrl: './fab-menu.component.html',
    styleUrls: ['./fab-menu.component.scss'],
    animations: [fabMenuAnimations]
})
export class FabMenuComponent {
    @Input()
    fabButtons: FabItemDescriptor[];

    @Input()
    tooltipOpened: string | null;

    @Input()
    tooltipClosed: string | null;

    @Input()
    icon: string | null;

    buttons: FabItemDescriptor[] = [];
    fabTogglerState: 'active' | 'inactive' = 'inactive';

    /**
     * Öffnet das Button-Menü
     */
    showItems(): void {
        this.fabTogglerState = 'active';
        this.buttons = this.fabButtons;
    }

    /**
     * Schließt das Button-Menü
     */
    hideItems(): void {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
    }

    /**
     * Toggled das Button-Menü
     */
    onToggleFab(): void {
        this.buttons.length ? this.hideItems() : this.showItems();
    }
}
