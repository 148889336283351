/**
 * Enumerationen für die k5Buergerservice-Datenklassen
 */

/**
 * Daten-Kategorie im Schnellsuche Suchergebnis
 */
export enum SchnellsucheKategorieEnum {
    Kontakte,
    Adressen,
    Listen,
    Gruppen
}

/**
 * Typ eines Elementes im Schnellsuche-Ergebnis
 */
export enum SchnellsucheErgebnisElementTypEnum {
    NatuerlichePerson,
    Unternehmen,
    Adresse,
    Gruppe,
    Liste
}

/**
 * Kontakttyp in der Detailsuche
 */
export enum KontaktSuchTyp {
    Alle,
    NatuerlichePerson,
    Unternehmen
}

/**
 * Statusfilter
 */
export enum Status {
    None,
    Ungueltig,
    Gueltig
}

/**
 * Art des Benutzers
 */
export enum BenutzerTyp {
    Normal = 0,
    Extern = 1,
    Administrator = 2
}

/**
 * Action Button Values
 */
export enum ActionButtonEnum {
    // Primärer Button
    Primary = 1,

    // Sekundärer Button
    Secondary = 2,

    // Tertiärer Button
    Tertiary = 3
}

/**
 * Enumerationen für die Zentrale Wahl
 */

/**
 * Wahltypen
 */
export enum Wahltyp {
    EU,
    Bund,
    Land,
    Gemeinde
}

/**
 * Unterschriften für die Wahlkarte
 */
export enum WahlkarteUnterschriftEnum {
    Buergermeister = 0,
    Wahlleiter = 1,
    Sachbearbeiter = 2
}

/**
 * Wahlstatus
 */
export enum WahlstatusEnum {
    Vorbereitung = 0,
    Anlage = 10,
    WahlbestandUpload = 12,
    WahlbestandGesamtimport = 14,
    GesamtwahlbestandAusgespielt = 16,
    WahlbestandImportAutomatisch = 20,
    WahlbestandImportManuell = 30,
    WahlbestandErstellt = 40,
    WahlbestandGeprueft = 50,
    WahlbestandFreigegeben = 60,
    KontaktWahllokaldatenKontrolliert = 70,
    DatenverknuepfungDurchgefuehrt = 80,
    DatenabgleichDurchgefuehrt = 83,
    WahlinformationDruckfreigabe = 90,
    Wahlabgeschlossen = 120,
    WahlHistorisch = 150
}

/**
 * Fortschritt im Wahlstatus
 */
export enum WahlstatusStepStateEnum {
    NotStarted = 0,
    InProgress = 1,
    Failure = 2,
    Completed = 3
}
