import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { NavItem, NAV_ITEMS } from '../models/nav-item';

/**
 * Service für die Navigation
 */
@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    // Navigationsitems in der Navigationsleiste
    private readonly navItemsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>(NAV_ITEMS);
    readonly navItems$: Observable<NavItem[]> = this.navItemsSubject.asObservable();

    // Aktives Navigationsitem
    private readonly selectedNavItemSubject: BehaviorSubject<NavItem> = new BehaviorSubject<NavItem>(null);
    readonly selectedNavItem$: Observable<NavItem> = this.selectedNavItemSubject.asObservable();

    // Zustand der Navigationsleiste
    private readonly sidenavOpenSubject = new BehaviorSubject<boolean>(true);
    readonly sidenavOpen$ = this.sidenavOpenSubject.asObservable();

    private readonly sidenavModeSubject = new BehaviorSubject<MatDrawerMode>('side');
    readonly sidenavMode$ = this.sidenavModeSubject.asObservable();

    private readonly sidenavSmallSubject = new BehaviorSubject<boolean>(false);
    readonly sidenavSmall$ = this.sidenavSmallSubject.asObservable();

    // Zustand, ob die Navigationsleiste ausgeklappt werden kann
    readonly sidenavExpandable$: Observable<boolean> = combineLatest([
        this.sidenavOpen$,
        this.sidenavMode$,
        this.sidenavSmall$
    ]).pipe(
        map(([sidenavOpen, sidenavMode, sidenavSmall]: [boolean, MatDrawerMode, boolean]) => {
            if ((sidenavMode === 'side' && sidenavSmall) || (sidenavMode === 'over' && !sidenavOpen)) {
                return true;
            }
            return false;
        })
    );

    constructor(
        private breakpointObserver: BreakpointObserver,
        private router: Router
    ) {
        // Beobachtet die Breite der Webseite und wechselt in die schmale Menüansicht, wenn die Breite unter 50rem ist
        this.breakpointObserver.observe('(max-width: 50rem)').subscribe((breakpointState: BreakpointState) => {
            if (breakpointState.matches) {
                this.setSidenavToOverlay();
            } else {
                this.setSidenavToSide();
            }
        });
    }

    /**
     * Gibt an, ob die Navigationsleiste geöffnet ist
     */
    get sidenavOpen(): boolean {
        return this.sidenavOpenSubject.value;
    }

    /**
     * Gibt den aktuellen Wert des Navigationsleisten-Modus zurück
     */
    get sidenavMode(): MatDrawerMode {
        return this.sidenavModeSubject.value;
    }

    /**
     * Gibt an, ob die Navigationsleiste klein oder groß dargestellt wird
     */
    get sidenavSmall(): boolean {
        return this.sidenavSmallSubject.value;
    }

    /**
     * Gibt die Navigtionsitems zurück
     */
    get navItems(): NavItem[] {
        return this.navItemsSubject.value;
    }

    /**
     * Gibt das selektierte Navigationsitem zurück
     */
    get selectedNavItem(): NavItem {
        return this.selectedNavItemSubject.value;
    }

    /**
     * Zeigt die Navigationsleiste als Overlay an
     */
    private setSidenavToOverlay() {
        this.sidenavModeSubject.next('over');
        this.sidenavSmallSubject.next(false);
        this.sidenavOpenSubject.next(false);
    }

    /**
     * Zeigt die Navigationsleiste seitlich fixiert an
     */
    private setSidenavToSide() {
        this.sidenavModeSubject.next('side');
        this.sidenavSmallSubject.next(false);
        this.sidenavOpenSubject.next(true);
    }

    /**
     * Umschalten der seitlichen Navigation
     */
    toggleSidenav(): void {
        if (this.sidenavMode === 'side') {
            this.sidenavSmallSubject.next(!this.sidenavSmall);
        } else {
            this.sidenavSmallSubject.next(false);
            this.sidenavOpenSubject.next(!this.sidenavOpen);
        }
    }

    /**
     * Schließt die seitliche Navigationsleiste
     */
    closeSidenav(): void {
        this.sidenavOpenSubject.next(false);
    }

    /**
     * Ersetzt die Navigationslinks eines Hauptmenüpunkts
     * @param parentRouterLink Router-Link des Hauptmenüpunkts
     * @param newNavItems NavigationsItems für den Hauptmenüpunkt
     */
    replaceNavItemsOfParent(parentRouterLink: string, newNavItems: NavItem[]): void {
        const navItems: NavItem[] = this.navItemsSubject.value;

        const parent = navItems.find((navItem) => navItem.routerLink === parentRouterLink);

        if (parent) {
            parent.children = newNavItems;
            this.navItemsSubject.next(navItems);
            this.handleUrlChange(this.router.url);
        }
    }

    /**
     * Aktualisiert den Zustand der Navigationsitems nach einer Änderung
     * des URLs.
     * @param url neuer URL
     */
    handleUrlChange(url: string): void {
        const selectedNavItem: NavItem = this.findNavItemToUrl(url);
        this.navItemsSubject.next(this.refreshVisibleNavItems(this.navItemsSubject.value, selectedNavItem));
        this.selectedNavItemSubject.next(selectedNavItem);
    }

    /**
     * Aktualisiert die Sichtbarkeit der Navigationsitems
     * @param navItems Navigationsitems
     * @param selectedNavItem Selektiertes Navigationsitem
     * @returns Neue Liste von Navigationsitems mit aktualisiertem Sichtbarkeitsattribut
     */
    private refreshVisibleNavItems(navItems: NavItem[], selectedNavItem: NavItem): NavItem[] {
        for (const navItem of navItems) {
            navItem.childrenVisible = this.isNavItemVisible(navItem, selectedNavItem);

            if (navItem.hasChildren) {
                this.refreshVisibleNavItems(navItem.children, selectedNavItem);
            }
        }
        return navItems;
    }

    /**
     * Prüft, ob ein Navigationsitem für die Anzeige im Navigationsbaum relevant ist
     * @param navItem Navigationslink
     * @param selectedNavItem
     * @returns true, wenn das Navigationsitem angezeigt wird, sonst false
     */
    private isNavItemVisible(navItem: NavItem, selectedNavItem: NavItem): boolean {
        return this.isNavItemVisibleRec(navItem, selectedNavItem);
    }

    /**
     * Prüft rekursiv, ob ein Navigationsitem für die Anzeige im Navigationsbaum relevant ist
     * @param navItem Navigationsitem für die Überprüfung
     * @param selectedNavItem Selektiertes Navigationsitem
     * @returns true, wenn das Navigationsitem für die Anzeige relevant ist, sonst false
     */
    private isNavItemVisibleRec(navItem: NavItem, selectedNavItem: NavItem): boolean {
        const selected = navItem.routerLink === selectedNavItem.routerLink;

        if (navItem.hasChildren && !selected) {
            for (const navChild of navItem.children) {
                if (this.isNavItemVisibleRec(navChild, selectedNavItem)) {
                    return true;
                }
            }
        }

        return selected;
    }

    /**
     * Gibt das zum URL passende Navigationsitem zurück.
     * @param url URL
     * @returns Navigationsitem, das der URL entspricht
     */
    private findNavItemToUrl(url: string): NavItem {
        // Top-level Navigationsitems überprüfen
        const navItem = this.findNavItemToUrlRec(url, this.navItemsSubject.value);

        return navItem || this.navItemsSubject.value[0];
    }

    /**
     * Sucht rekursiv nach dem aktuell relevanten NavigationsItem.
     * @param url URL
     * @param navItems Navigationsitems
     * @returns Navigationsitem, das der URL entspricht
     */
    private findNavItemToUrlRec(url: string, navItems: NavItem[]): NavItem | null {
        for (const navItem of navItems) {
            if (this.isRouteActive(navItem.routerLink, navItem.exactUrlActiveMatching)) {
                return navItem;
            } else if (navItem.hasChildren) {
                const childItem = this.findNavItemToUrlRec(url, navItem.children);
                if (childItem) {
                    return childItem;
                }
            }
        }
        return null;
    }

    /**
     * Prüft, ob eine Route aktuell aktiv ist.
     * @param activeRoute Aktive URL
     * @returns true, wenn die URL aktuell aktiv ist sonst false
     */
    private isRouteActive(activeRoute: string, exactUrlActiveMatching: boolean = true): boolean {
        return this.router.isActive(activeRoute, {
            matrixParams: 'ignored',
            queryParams: 'ignored',
            paths: exactUrlActiveMatching ? 'exact' : 'subset',
            fragment: 'ignored'
        });
    }

    /**
     * Setzt die Nav Items
     * @param navItems Nav Items
     */
    setNavItems(navItems: NavItem[]): void {
        this.navItemsSubject.next(navItems);
    }
}
