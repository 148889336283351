import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ProblemAlert } from '@core/models/problemAlert';

@Component({
    selector: 'k5-problem-alert',
    templateUrl: './problem-alert.component.html',
    styleUrls: ['./problem-alert.component.scss']
})
export class ProblemAlertComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public problemAlert: ProblemAlert) {}

    /**
     * Gibt an, ob Details vorhanden sind
     */
    get detailsAvailable(): boolean {
        return !!this.problemAlert.problem;
    }

    /**
     * Gibt an, ob es Detailwerte gibt
     */
    get detailValuesAvailable(): boolean {
        return this.problemAlert?.problem?.detailValues && this.problemAlert.problem.detailValues.length > 0;
    }
}
